import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './ASA LOGO.png';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  let lastScrollY = window.pageYOffset;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.pageYOffset;
    setIsVisible(lastScrollY > currentScrollY || currentScrollY < 10);
    lastScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <div className={`line line1 ${isOpen ? 'open' : ''}`}></div>
          <div className={`line line2 ${isOpen ? 'open' : ''}`}></div>
        </div>
      </div>
      <ul className={`navbar-list ${isOpen ? 'open' : ''}`}>
        <li className="navbar-item">
          <Link to="/team" onClick={toggleMenu}>Our Team</Link>
        </li>
        <li className="navbar-item">
          <Link to="/events" onClick={toggleMenu}>Our Events</Link>
        </li>
        <li className="navbar-item">
          <Link to="/opportunities" onClick={toggleMenu}>Opportunities</Link>
        </li>
        <li className="navbar-item">
          <Link to="/member" onClick={toggleMenu}>Become a member</Link>
        </li>
        <li className="navbar-item">
          <Link to="/connect" onClick={toggleMenu}>Connect with us</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;