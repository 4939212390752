import React from 'react';
import './Newsletter.css';

const Newsletter: React.FC = () => {
  return (
    <div className='newsletter-container'>
      <h1>Newsletter</h1>
      <p>Coming September 2024</p>
      <span className="material-symbols-outlined">
        hourglass_top
      </span>
    </div>
  );
};

export default Newsletter;
