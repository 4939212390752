import React, { useState } from 'react';
import './Team.css';
import team_image from './team.jpg'; // Adjust the path to your image

const Team: React.FC = () => {
    const [openJob, setOpenJob] = useState<string | null>(null);

    const toggleJobDescription = (job: string) => {
        setOpenJob(openJob === job ? null : job);
    };

  return (
    <div className="team-container">
        <h1 className="team-title">Join our Team !</h1>
      {/* <img src={team_image} alt="Team" className="team-image" /> */}
      <p className="team-paragraph">We are currently hiring for the 2024-2025 academic year. Check our listings below:</p>
      <ul className="team-list">
        <li className="team-list-item" onClick={() => toggleJobDescription('First Year Rep')}>
            {openJob !== 'First Year Rep' ? (
            <>First Year Rep</>
            ) : (
            <div className="job-description">
                <p>The First-Year Rep for the African Students’ Association is the bridge between new students and the club, helping them get involved and stay connected.</p>
                <a href="https://forms.gle/L5LtsfgjK2USvk8a7" target="_blank" rel="noopener noreferrer">
                <button className="apply-button">Apply</button>
                </a>
            </div>
            )}
        </li>
        </ul>
    </div>
  );
};

export default Team;
