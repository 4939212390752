import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Events.css';

const Events: React.FC = () => {
  return (
    <div className="events-container">
      <h1>Our Events</h1>
      <p>We are working hard to plan exciting events for the upcoming year. Stay tuned for updates!</p>
      <div className="calendar-container">
        <Calendar />
      </div>
    </div>
  );
};

export default Events;
