import React, { useState } from 'react';
import './Member.css';

const Member: React.FC = () => {
  const [formData, setFormData] = useState({
    'entry.83595027': '',
    'entry.1223617492': '',
    'entry.967240065': '',
    'entry.2070754352': '',
    'entry.992528847': '',
    'entry.2114010055': '',
    'entry.517776943': ''
  });

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const queryString = new URLSearchParams(formData).toString();
    const googleFormUrl = `https://docs.google.com/forms/d/e/1FAIpQLScyTXefIxcX0aFMgtV9_0Hhe7orRIKB6Ij7PmPMAtSAYL_4Bw/formResponse?${queryString}`;
    
    // Log the constructed URL for debugging purposes
    console.log('Submitting to Google Form URL:', googleFormUrl);

    fetch(googleFormUrl, { method: 'POST', mode: 'no-cors' })
      .then(() => {
        setMessage('Form submitted successfully!');
        setError(null);
        setFormData({
          'entry.83595027': '',
          'entry.1223617492': '',
          'entry.967240065': '',
          'entry.2070754352': '',
          'entry.992528847': '',
          'entry.2114010055': '',
          'entry.517776943': ''
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        setMessage(null);
        setError('There was an error submitting the form. Please try again.');
      });
  };

  return (
    <div className="member-container">
      <h1>Join Us </h1>
      {message && <div className="message-box success">{message}</div>}
      {error && <div className="message-box error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="entry.83595027"
            value={formData['entry.83595027']}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="entry.1223617492"
            value={formData['entry.1223617492']}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Campus:
          <select
            name="entry.967240065"
            value={formData['entry.967240065']}
            onChange={handleChange}
            required
          >
            <option value="">Select your campus</option>
            <option value="UTSC">UTSC</option>
            <option value="UTSG">UTSG</option>
          </select>
        </label>
        <br />
        <label>
          Program of Study:
          <input
            type="text"
            name="entry.2070754352"
            value={formData['entry.2070754352']}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          What year are you in?
            <select
                name="entry.992528847"
                value={formData['entry.992528847']}
                onChange={handleChange}
                required
            >
            <option value="1st+year">1st year</option>
            <option value="2nd+year">2nd year </option>
            <option value="3rd+year">3rd year</option>
            <option value="4th+year+and+up">4th year and up</option>
            </select>
        </label>
        <label>
          What are your expectations for ASA this year?
          <textarea
            name="entry.2114010055"
            value={formData['entry.2114010055']}
            onChange={handleChange}
            required
          />

        </label>
        <br />
        <label>
          Phone Number:
          <input
            type="tel"
            name="entry.517776943"
            value={formData['entry.517776943']}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default Member;
