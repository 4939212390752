import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Team from './pages/Team/Team';
import Opportunities from './pages/Opportunities/Opportunities';
import Member from './pages/Member/Member';
import Connect from './pages/Connect/Connect';
import Events from './pages/Events/Events';
import Donate from './pages/Donate/Donate';
import './App.css';
import Footer from './components/Footer/Footer';
import Newsletter from './pages/Newsletter/Newsletter';

const App: React.FC = () => {
  return (
   <>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/events" element={<Events />} />
        <Route path="/team" element={<Team />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/member" element={<Member />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/newsletter" element={<Newsletter />} />
      </Routes>
      <div className="App">
      </div>
      <Footer/>
    </>
  );
};


export default App;
