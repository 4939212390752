import React from 'react';
import './Opportunities.css';

const Opportunities: React.FC = () => {
  return (
    <div className='opportunities-container'>
      <h1>Opportunities</h1>
      <p>Coming Soon</p>
      <span className="material-symbols-outlined">
        hourglass_top
      </span>
    </div>
  );
};

export default Opportunities;
