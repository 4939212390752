import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
import logo from '../Navbar/ASA LOGO.png'; // Adjust the path to your logo image
 
const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src={logo} alt="Club Logo" className="footer-logo" />
          <h1 className="club-name">African Student Association</h1>
        </div>
        <div className="footer-section contact-section">
          <p><FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:asa.utsc@studentorg.utoronto.ca" className="footer-mailto">Email: asa.utsc@studentorg.utoronto.ca</a></p>
          <p>Address: 1265 Military Trail, Toronto, ON, M1C 0A4</p>
            <div className="footer-section social-media-section">
                <a href="https://www.linkedin.com/in/utsc-african-students-association-2451b8129/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                </a>
                <a href="https://www.instagram.com/utscasa/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </a>
                <a href="https://www.tiktok.com/@utscasa/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} className="social-icon" />
                </a>
            </div>
        </div>
        
        <div className="footer-section links-section">
          <a href="/donate" className="footer-link">Donate</a>
          <a href="/newsletter" className="footer-link">Newsletter</a>
          <a href="/connect" className="footer-link">Contact Us</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
