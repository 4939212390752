import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import TextareaAutosize from 'react-textarea-autosize';
import './Connect.css';


const Connect: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    message: ''
  });

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID!
        )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatusMessage('Your message has been sent successfully!');
        setErrorMessage(null);
        setFormData({
          firstName: '',
          lastName: '',
          subject: '',
          email: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setStatusMessage(null);
        setErrorMessage('There was an error sending your message. Please try again.');
      });
  };

  return (
    <div className="connect-container">
      <h1>Connect with Us</h1>
      {statusMessage && <div className="message-box success">{statusMessage}</div>}
      {errorMessage && <div className="message-box error">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Subject:
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          >
            <option value="">Select a subject</option>
            <option value="Partnership">Partnership</option>
            <option value="Community & Volunteering">Community & Volunteering</option>
            <option value="Financial Aid/Scholarship">Financial Aid/Scholarship</option>
            <option value="Donations">Donations</option>
            <option value="Events">Events</option>
            <option value="Opportunities">Opportunities</option>
            <option value="General">General</option>
          </select>
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Message:
          <TextareaAutosize
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            minRows={3}
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </label>
        <br />
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Connect;
