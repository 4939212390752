import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Home.css';
import image6 from './image6.jpg';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
import image3 from './image3.jpg';
import image4 from './image4.jpg';
import image5 from './image5.jpg';

const Home: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
    <div className="home">
      <div className="caption">
        <h1>Building a community for African Students at the University of Toronto since 2010.</h1>
      </div> 

     {/* Carousel Section */}
        <div className="carousel">
          <Slider {...settings}>
            <div>
              <img src={image6} alt="Image 1" />
            </div>
            <div>
              <img src={image1} alt="Image 2" />
            </div>
            <div>
              <img src={image5} alt="Image 3" />
            </div>
            <div>
              <img src={image3} alt="Image 3" />
            </div>
            {/* Add more images as needed */}
          </Slider>
        </div>
      
      <div id="home-content" className="home-content">
        <h1>PROUDLY Africans</h1>
          <p>
            The African Students' Association (ASA) is a student-run organization at the University of Toronto.
            Our mission is to create an inclusive environment that celebrates the unique contributions of African 
            students while fostering love, acceptance, and a strong sense of community.
          </p>
          <p>
          Through a diverse range of programs and events, we aim to celebrate African heritage 
          and foster a sense of belonging among African students. Our annual events 
          include cultural festivals, academic workshops, and social gatherings, 
          all designed to create a supportive and engaging community.
          </p>
          <p>
            ASA is deeply committed to advocacy and the promotion of social justice, 
            working to ensure that the campus is an equitable space for all African 
            students. We engage in various initiatives to raise awareness about issues
            affecting the African community and to support our members in their academic
            and personal growth.
          </p>
      </div>
      </div>

      <div className="team-content">
        <h1>Our Team</h1>
        <button>
          <a href="/team" className="team-link">Apply to join our team</a>
        </button>
      </div>
    </>
  );
};

export default Home;
