import React from 'react';
import './Donate.css';

const Donate: React.FC = () => {
  return (
    <div className='donations-container'>
      <h1>Donations</h1>
      <p>Coming Soon</p>
      <span className="material-symbols-outlined">
        hourglass_top
      </span>
    </div>
  );
};

export default Donate;
